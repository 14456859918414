<template>
  <div class="row g-6 g-xl-4 mb-3">
    <template v-if="cardData.length">
      <template v-if="stretched">
        <div class="col-12 col-xl-12 col-xxl-12" v-for="(card, i) in cardData" v-bind:key="i">
          <!--begin::Card-->
          <slot name="card-info" :card="card">

          </slot>

        </div>
        <Pagination :total="total" :current-page="currentPage" :item-per-page="rowsPerPage" class="text-lg-right"
                    v-on:current-change="paginationChange"/>
        <!--begin::Col-->
      </template>
      <template v-else>
        <div class="col-12 col-xl-12 col-xxl-6" v-for="(card, i) in cardData" v-bind:key="i">
          <!--begin::Card-->
          <slot name="card-info" :card="card">

          </slot>

        </div>
        <div class="col-md-12">
          <Pagination :total="total" :current-page="currentPage" :item-per-page="rowsPerPage" class="text-lg-right"
                      v-on:current-change="paginationChange"/>
        </div>

        <!--begin::Col-->
      </template>
    </template>
    <template v-else>
      <div class="col-md-12 col-xl-12 col-xxl-12">
        <div class="card">
          <div class="card-body">
            No data found
          </div>
        </div>
      </div>
    </template>
    <!--begin::Col-->
  </div>
</template>

<script>
import Pagination from "@/components/base/paging/Pagination";

export default {
  name: "Cards",
  components: {Pagination},
  props: {
    stretched: {type: Boolean, default: false},
    cardData: {type: Array, required: true},
    currentPage: {type: Number, default: 1},
    total: {type: Number, default: 0},
    rowsPerPage: {type: Number, default: 10},
  },
  emits: ['current-change'],
  setup(_, {emit}) {
    const paginationChange = (val) => {
      emit('current-change', val)
    }
    return {
      paginationChange
    }
  }
}
</script>

<style scoped>

</style>