<template>
  <div class="card ">
    <div class="card-header">
      <h4 class="card-title"> Summary</h4>
    </div>
    <div class="card-body pt-3 pb-3">
      <Row :row="[{name: 'Billed', value: '', slot:true, key:'billed'}]">
        <template v-slot:billed>
          <CurrencyFormat :amount="claim.billed" :currency="`CAD`"/>
        </template>
      </Row>
      <Row :row="[{name: 'Paid', value: '', slot:true, key:'paid'}]">
        <template v-slot:paid>
          <CurrencyFormat :amount="claim.paid" :currency="`CAD`"/>
        </template>
      </Row>
      <Row :row="[{name: 'Discount', value: '', slot:true, key:'discount'}]">
        <template v-slot:discount>
          <CurrencyFormat :amount="claim.discount" :currency="`CAD`"/>
        </template>
      </Row>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent} from "vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat";
import Row from "@/components/base/common/Row";
import store from "@/store";

export default defineComponent({
  name: "Accounting",
  components: {Row, CurrencyFormat},
  setup(){
   const claim =  computed(()=>store.state.ClaimModule.claim);
   return {
     claim,
   }
  }
})
</script>

<style scoped>

</style>